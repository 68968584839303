import Link from 'next/link'
import Image from 'next/image'
import { HBox } from './HBox'

export function AppButtons() {
  return (
    <HBox
      sx={{
        mt: 1.5,
        gap: 4,
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
    >
      <Link
        rel="noreferrer noopener"
        href="https://play.google.com/store/apps/details?id=pl.demokracja.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <Image
          alt="pobierz z Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
          width={260}
          height={86.667}
          style={{ display: 'block' }}
        />
      </Link>
      <Link
        rel="noreferrer noopener"
        href="https://apps.apple.com/pl/app/aplikacja-demokracja/id6448667372"
      >
        <Image
          src="/appStoreBadgePL.svg"
          alt="pobierz z App Store"
          width={200}
          height={66.667}
          style={{ display: 'block' }}
        />
      </Link>
    </HBox>
  )
}
