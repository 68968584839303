import { Box, BoxProps } from '@mui/material'

export function VBox({ sx, children, ...props }: BoxProps) {
  // @ts-ignore
  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      {children}
    </Box>
  )
}

VBox.muiName = 'VBox'
