import { Box, BoxProps } from '@mui/material'

export function HBox({ sx, children, ...props }: BoxProps) {
  // @ts-ignore
  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
      {children}
    </Box>
  )
}

HBox.muiName = 'HBox'
