import { Box, CircularProgress, Typography } from '@mui/material'
import Image from 'next/image'
import { AppButtons } from './AppButtons'
import { VBox } from './VBox'
import { HBox } from './HBox'
import Link from 'next/link'

export interface MobilePresentationProps {
  isLoading?: boolean
}

export function MobilePresentation({
  isLoading = false
}: MobilePresentationProps) {
  return (
    <VBox sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <VBox sx={{ alignItems: 'center' }}>
            <Typography variant="h4" sx={{ mt: 3, mb: 1.5 }}>
              Aplikacja Demokracja
            </Typography>
            <Image
              src="/logo.png"
              alt="Aplikacja Demokracja - logo"
              width={120}
              height={120}
            />
            <Typography variant="h5" sx={{ textAlign: 'center', my: 1.5 }}>
              Obserwuj głosowania i&nbsp;wypowiedzi posłów na Sejm RP.
            </Typography>
            <AppButtons />
          </VBox>
        )}
      </Box>
      <HBox sx={{ justifyContent: 'center', my: 4 }}>
        <Link href="mailto:kontakt@demokracja.app">kontakt@demokracja.app</Link>
      </HBox>
    </VBox>
  )
}
